import PageRender from '../../models/PageRender';
import Component from 'vue-class-component';
import Site from '../../models/Site';
import { siteService, featureService } from '@/main';
import Feature from '../../models/Feature';
import Vue from 'vue';
import { BModal } from 'bootstrap-vue';
import { KendoGrid } from '@progress/kendo-grid-vue-wrapper';
import to from 'await-to-js';
import { AxiosResponse } from 'axios';
import Place from '../../models/Place';

@Component
export default class AccommodationFeatures extends PageRender {
    public site: Site = {} as Site;
    public places: Place[] = [] as Place[];
    public mode: string = '';
    public isLoading: boolean = true;
    public editMode: boolean = false;
    public features: Feature[] = null;
    public featureGroups: any[] = null;
    public newFeature: Feature = new Feature();
    public featureToDelete: Feature = {} as Feature;
    public placesWithFeature: Place[] = [] as Place[];
    public isLoadingPlacesWithFeatures: boolean = false;
    public systemScope: boolean = false;

    public featureGridColumns = [
        { field: 'name', title: 'Naam' },
        { field: 'isSystemFacility', cell: this.renderSystemFeature, title: 'Systeemeigenschap', filterCell: 'boolean' },
        { field: 'featureGroup.name', title: 'Categorie' },
        { title: 'Acties', cell: this.renderActions, width: '150px' },
    ];

    public $refs!: {
        featureModal: BModal;
        featureGrid: KendoGrid;
        deleteFeatureModal: BModal;
    };

    public async created() {
        await this.initAccoBase();
        this.isLoading = false;

        if (this.$route.query.feature) {
            this.editFeature(this.features.find((x) => x.featureId === parseInt(this.$route.query.feature as string, 10)));
        }
    }

    public openAddFeatureModal() {
        this.newFeature = new Feature();
        this.newFeature.isSystemFeature = this.systemScope;
        this.$refs.featureModal.show();
    }

    public renderSystemFeature(h: any, a: any, row: any): any {
        return h('td', [row.dataItem.isSystemFeature ? 'Ja' : 'Nee']);
    }

    public renderActions(h: any, a, row): any {
        const actions = [
            { title: 'Bewerk', function: this.editFeature },
            {
                title: 'Verwijder',
                function: this.systemScope
                    ? function() {
                          this.deleteFeature(row.dataItem);
                      }
                    : this.confirmDeleteFeature,
            },
        ];

        const item = row.dataItem;
        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }

    public editFeature(item) {
        this.newFeature = item as Feature;
        this.newFeature.name = item.name;
        this.newFeature.featureId = item.featureId;

        this.$refs.featureModal.show();
    }

    public selectFeatureGroup(featureGroup: any) {
        this.newFeature.featureGroupId = featureGroup.featureGroupId;
    }

    public async confirmDeleteFeature(item) {
        this.featureToDelete = item as Feature;
        this.$refs.deleteFeatureModal.show();

        this.isLoadingPlacesWithFeatures = true;
        this.placesWithFeature = await this.getPlacesWithFeature(this.featureToDelete);
        this.isLoadingPlacesWithFeatures = false;
    }

    public cancelDeleteFeature() {
        this.featureToDelete = {} as Feature;
        this.$refs.deleteFeatureModal.hide();
    }

    public async getPlacesWithFeature(feature: Feature) {
        const [err, response] = await to(siteService.getPlaceIdsWithFeature(this.siteKey, feature));
        if (err) {
            this.showFailedResponse(`Mislukt om accommodaties op te halen met eigenschap: ${feature.name}`, err);
        }
        const placeIds = response.data;
        const places = [];
        placeIds.forEach((placeId) => {
            places.push(
                this.places.find((place) => {
                    return place.placeId === placeId;
                }),
            );
        });
        return places;
    }

    public async deleteFeature(item) {
        const [err, types] = await to(featureService.deleteFeature(item.featureId));
        if (err) {
            this.showFailedResponse('Eigenschap verwijderen mislukt', err);
            return;
        }

        const index = this.features.indexOf(item);
        this.features.splice(index, 1);

        this.$refs.deleteFeatureModal.hide();
        return this.clearAndShowSuccess(`Eigenschap <b>${item.name}</b> verwijderd.`);
    }

    public async upsertFeature() {
        const self = this;
        this.showPending('Eigenschap wordt opgeslagen...');
        const [err, result] = await to<AxiosResponse<Feature>>(
            featureService.createOrUpdateFeature({
                name: this.newFeature.name,
                featureId: this.newFeature.featureId,
                amount: 1,
                isSystemFeature: this.systemScope,
                featureGroupId: this.newFeature.featureGroupId,
                featureGroup: null,
            }),
        );

        if (err) {
            this.clearAndShowError('Mislukt om eigenschap op te slaan', err);
        }

        this.$refs.featureModal.hide();
        this.clearAndShowSuccess('Eigenschap succesvol opgeslagen');

        if (!this.newFeature.featureId) {
            const feature = result.data;
            feature.featureGroup = self.featureGroups.find((group) => group.featureGroupId === feature.featureGroupId);

            this.features.push(feature as Feature);
        } else {
            const feature = this.features.find((f) => f.featureId === self.newFeature.featureId);
            feature.featureGroup = self.featureGroups.find((group) => group.featureGroupId === feature.featureGroupId);
        }
    }

    private async initAccoBase() {
        const self = this;
        this.systemScope = typeof this.$route.params.siteKey === 'undefined';
        this.mode = this.$route.params.mode;
        this.featureGroups = await this.getFeatureGroups();

        if (!this.systemScope) {
            this.places = (await siteService.getPlaces(this.siteId)).data;
        }

        const features = await this.getFeatures();
        features.forEach((feature) => (feature.featureGroup = self.featureGroups.find((group) => group.featureGroupId === feature.featureGroupId)));
        this.features = features;
    }

    private async getFeatures() {
        const [err, response] = await to(featureService.getFeatures()); // only need to pass something to the api to get only the stuff of the current siteOwner
        if (err) {
            this.showFailedResponse('Mislukt om faciliteiten op te halen', err);
            return [];
        }
        return response.data;
    }

    private async getFeatureGroups() {
        const [err, response] = await to(featureService.getFeatureGroups());
        if (err) {
            this.showFailedResponse('Mislukt om faciliteitgroepen op te halen', err);
            return [];
        }
        return response.data;
    }
}
